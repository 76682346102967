import { locationSearchMapConfig } from "./config";
import { moveMapTo } from "./moveMapTo";
import { ResultsList } from "./ResultsList";
import { locationSearchMapConfig as config } from './config';
import { LatLng } from "./models/LatLng";

export class Search
{
    map: H.Map;
    resultsList: ResultsList;
    searchResMapIcon: H.map.Icon;
    marker: H.map.Marker;
    pinFirstResult: boolean = false;
    constructor(map: H.Map, resultsList: ResultsList)
    {
        this.map = map;
        this.resultsList = resultsList;
        this.searchResMapIcon = new H.map.Icon(searchResMarker);
        this.map.addEventListener('mapviewchangeend', (event: any) => {
            const viewModel = this.map.getViewModel();
            this.resultsList.filterResults(viewModel.getLookAtData().bounds.getBoundingBox());
            const objects = this.map.getObjects();
            for(let i=0; i<objects.length; ++i) {
                if(objects[i].type === H.map.Object.Type.MARKER) {
                    this.resultsList.sortCurrentListByDistanceToPin(this.marker.getGeometry());
                    
                }
            }
            if(this.pinFirstResult)
            {
                this.resultsList.pinFirstResult();
                
            }
            else 
            {
                this.resultsList.unpinFirstResult();
            }
            this.resultsList.updateListView();
        });
        document.getElementById('location-search-search-text-input').addEventListener('keydown', (e: KeyboardEvent) =>
        {
            if (e.code === 'Enter')
            {
                const searchText = (e.target as HTMLInputElement).value;
                this.search(searchText);
            }
        });
        document.getElementById('location-search-btn').addEventListener('click', (e: MouseEvent) =>
        {
            const searchText = (document.getElementById('location-search-search-text-input') as HTMLInputElement).value;
            this.search(searchText);
        });
        document.getElementById('know-my-location-btn').addEventListener('click', (e: MouseEvent) =>
        {
            this.knowMyLocationSearch();
        });
    }

    search = async (searchQuery: string) =>
    {
        //console.log('search: searchQuery: ', searchQuery);
        searchQuery = searchQuery.trim();
        this.setPinFirstResult(searchQuery);
        if (searchQuery.length === 0)
        {
            console.log('reset search and map');
            this.resetMap();
            this.resultsList.resetList();
        }
        const resDBASearch = this.resultsList.searchByDBAName(searchQuery);
        localStorage.setItem("resDBASearchLength",resDBASearch.length);
        //console.log('search: searchByDBAName: ', resDBASearch);
        if (this.isDBASearch(searchQuery))
        {
            this.loadSearchByDBAResult(resDBASearch);
            return;
        }
        const res = await this.heremapSearch(searchQuery);
        //console.log('search: res: ', res);

        if (res.items && res.items.length > 0)
        {
            let bbox: H.geo.Rect;
            const topRes = res.items[0];
            
            let type = res.items[0][`${res.items[0].resultType}Type`];
            if (res.items[0].resultType === 'street' || res.items[0].resultType === 'place')
            {
                type = res.items[0].resultType;
            }

            this.focusMap(res.items[0].position, type);
            this.addMarker(res.items[0].position);
        }
        else
        { // search by dba 
            this.loadSearchByDBAResult(resDBASearch);
        }

    }
    heremapSearch = async (searchQuery: string) =>
    {
        const apiUrl = `${locationSearchMapConfig.geoCodeSearchApiUrl}?apiKey=${locationSearchMapConfig.apiKey}&q=${searchQuery}&in=countryCode:CAN`;
        const res = await fetch(apiUrl)
            .then(data => data.json());
        return res;
    }
    resetMap = () =>
    {
        moveMapTo(this.map,
            { lat: Number(config.initView.lat), lng: Number(config.initView.lng) },
            Number(config.initView.zoom));
    }
    focusMap = (position: LatLng, areaType: string) =>
    {
        let zoom = this.getZoom(areaType);
        moveMapTo(this.map, position, zoom);
    }
    knowMyLocationSearch = () =>
    {
        navigator.geolocation.getCurrentPosition((position) =>
        {
            //console.log('knowMyLocation: position: ', position);
            const coords = `${position.coords.latitude},${position.coords.longitude}`;
            const revGeocodeUrl = `${config.revGeoCodeSearchApiUrl}?at=${coords}&apiKey=${config.apiKey}`
            fetch(revGeocodeUrl)
                .then(data => data.json())
                .then(res =>
                {
                    if (res.items.length > 0)
                    {
                        const zipcode = res.items[0].address.postalCode;
                        const searchBox = document.getElementById('location-search-search-text-input') as HTMLInputElement;
                        searchBox.value = zipcode;
                        this.search(zipcode);
                    }
                });
        });
    }
    
    loadSearchByDBAResult = (resDBASearch: any) =>
    {
        if (resDBASearch.length > 0)
        {
            //console.log('loadSearchByDBAResult: focusing by dba name: ');
            const topResDBASearch = resDBASearch[0].item;

            const bbox = this.getBoundingBox(topResDBASearch.latitude, topResDBASearch.longitude, 0.125);
            this.resultsList.filterResults(bbox);
            const position = { lat: topResDBASearch.latitude, lng: topResDBASearch.longitude };
            this.focusMap(position, 'place');
            this.addMarker(position);
        }else {
            document.querySelector('.locations-found-text').innerHTML = $('#no-locs-available-msg').html().toString().trim();
            $(".location-bar-col").addClass("d-none");
            return;
            
        }
    }
    
    getBoundingBox = (lat: number, lng: number, range: number): H.geo.Rect =>
    {
        /* 
            construct a rect using lat and long:
            north and south - calculate from lat 
                north = lat + x
                south = lat - x
            east and west - calculate from long
                east = long + x
                west = long - x
        */
        const north = lat + range;
        const south = lat - range;
        const east = lng + range;
        const west = lng - range;
        const bbox = new H.geo.Rect(north, west, south, east);
        return bbox;
    }
    getZoom = (areaType: string) => {
        let zoom = 4;
        if (areaType === 'street')
        {
            zoom = 16;
        }
        else if (areaType === 'place')
        {
            zoom = 14;
        }
        else if (areaType === 'postalCode')
        {
            zoom = 9; // 20km zoom
        }
        else if (areaType === 'city')
        {
            zoom = 9; // 20km zoom
        }
        else if (areaType === 'district')
        {
            zoom = 9; 
        }
        else if (areaType === 'state')
        {
            zoom = 6;
        }
        else if (areaType === 'country')
        {
            zoom = 5;
        }
        return zoom;
    }
    addMarker = (position: LatLng) => {        
        if(this.marker) {
            this.map.removeObject(this.marker);
        }
        this.marker = new H.map.Marker(position, 
            {icon: this.searchResMapIcon});
        this.map.addObject(this.marker);
    }
    setPinFirstResult = (query: string) => {
        this.pinFirstResult = this.isDBASearch(query) || this.isZipSearch(query);
    }
    isDBASearch = (query: string) => {
        return query.replace(/\W/g, "").toLowerCase().includes('mrhandymanof');
    }
    isZipSearch = (query: string) => {
        return /\d{5}/.test(query.trim());
    }
}
const searchResMarker = '<svg xmlns="http://www.w3.org/2000/svg" id="a" viewBox="0 0 59 59" width="44" height="42"><defs><style>.b{fill:#fff;}</style></defs><path d="M47.86,23.09c0-11.23-9.11-20.34-20.34-20.34S7.18,11.86,7.18,23.09c0,6.89,3.44,12.98,8.69,16.66l11.66,11.66,11.66-11.66c5.25-3.68,8.69-9.76,8.69-16.66Z"/><circle class="b" cx="27.52" cy="23.09" r="12.27"/></svg>';