import { LocationSearchMapCpkConfig } from "./models/LocationSearchMapCpkConfig";

export const getLocationSearchMapCpkConfig = (): LocationSearchMapCpkConfig => {    
    return {
        showPhone: $('#show-phone').val().toString().trim() === 'yes' ? true : false,
        phoneText: $('#phone-label').val().toString().trim(),
        phoneViewType: $('#phone-view-type').val().toString().trim(),

        showViewWebsite: $('#show-view-website').val().toString().trim() === 'yes' ? true : false,
        viewWebsiteText: $('#view-website-label').val().toString().trim(),
        viewWebsiteViewType: $('#view-website-view-type').val().toString().trim(),

        showRequestEstimate: $('#show-request-estimate').val().toString().trim() === 'yes' ? true : false,
        requestEstimateText: $('#request-estimate-label').val().toString().trim(),
        requestEstimateViewType: $('#request-estimate-view-type').val().toString().trim(),

        
        noLocationsAvailable: $('#no-locs-available-msg').html().toString().trim()
    }
}
// invalidUserInputMsg: $('#invalid-user-input-msg').val().toString().trim(),