import { getConceptCode } from "./getConceptCode"
export const locationSearchMapConfig = {    
    initView: {
        lat: $("#countryCode").val() === 'us' ? '43' : '50',
        lng: $("#countryCode").val() === 'us' ? '-99' : '-100',
        zoom: '4',
        mobileZoom:'2.2',
    },
    franchiseWebLocationsApiCall: `${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_FranchiseGroup_URL}/v1/public/concepts/${getConceptCode()}/franchiseweblocations?apikey=${window.DEFINE_VALUE.JS_API_KEY}`,
    apiKey: 'JM8QbmZwlyQePH_AKtYlsKu69whGkWYGocqgWEk4Jqc',
    geoCodeSearchApiUrl: 'https://geocode.search.hereapi.com/v1/geocode',
    revGeoCodeSearchApiUrl: 'https://geocode.search.hereapi.com/v1/revgeocode',
    fullAttributeApiUrl: `${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_FranchiseGroup_URL}/v1/public/franchiseGroupOrchestrator/`,
    nblyApiKey: window.DEFINE_VALUE.JS_API_KEY,
    mobileViewWidth: 992,
    desktopMapContainerCol: 'desktop-map-container-col',
    mobileMapContainerCol: 'mobile-map-container-col'
}
// set level to 'debug' if you want to fetch data from ./franchiseWebLocations.json
export const debugConfig = {
    level: 'prod'
}