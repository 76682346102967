import { formatPhone } from "./formatPhone";
import { FranchiseDetail } from "./models/FranchiseDetail";

export const getBubbleContent = (franchiseDetail: FranchiseDetail): string =>
{
    let addressLine_1_2 = `<span><i>${franchiseDetail.address1}, ${franchiseDetail.address2 ? franchiseDetail.address2 : ''}</i></span>`;
    addressLine_1_2 = franchiseDetail.showAddress ? addressLine_1_2 : '';
    
    var websiteTrackingNumber:any = franchiseDetail.websiteTrackingPhone[0] ? franchiseDetail.websiteTrackingPhone[0] : franchiseDetail.phoneNumber;

    return `<div class="location-bar-map">
    <h6><a>${franchiseDetail.doingBusinessAs}</a></h6>
    ${addressLine_1_2}
    <div class="call-bttn"><a href="tel:${websiteTrackingNumber}">${websiteTrackingNumber.includes('(') ? websiteTrackingNumber : formatPhone(websiteTrackingNumber)}</a></div>
    <div class="bttns-group"><a href="#" class="" data-toggle="modal" data-target="#js-schedulerDialog">${$('.desktop-btn a').html()}</a><a href="${franchiseDetail.websiteUrl}" class="">View Website</a></div>
    </div>`;   
}