import { Cluster } from "./Cluster";
import { FullAttributeContainer } from "./FullAttributeContainer";
import { getFranchiseWebLocations } from "./getFranchiseWebLocations";
import { FranchiseDetail } from "./models/FranchiseDetail";
import { renderMap } from "./renderMap";
import { reportScreenSize } from "./reportScreenSize";
import { ResultsList } from "./ResultsList";
import { ScreenType } from "./models/ScreenType";
import { Search } from "./Search";
import { stopLoader } from "./stopLoader";

export class App 
{
    webLocations: void | FranchiseDetail[];
    map: H.Map;
    ui: H.ui.UI;
    cluster: Cluster;
    resultsList: ResultsList;
    search: Search;
    screenType: ScreenType;
    fullAttributeContainer: FullAttributeContainer;

    constructor()
    {
        this.init = this.init.bind(this);
        this.loadData = this.loadData.bind(this);
        this.loadMapAndResultsList = this.loadMapAndResultsList.bind(this);
        this.loadMapAndCluster = this.loadMapAndCluster.bind(this);
        this.windowResizeCallback = this.windowResizeCallback.bind(this);
        this.desktopView = this.desktopView.bind(this);
        this.mobileView = this.mobileView.bind(this);
        this.init();        
    }
    init = async () =>
    {
        //console.log('welcome to location search map page');        
        await this.loadData();
        if(this.webLocations)
        {            
            this.screenType = reportScreenSize();
            if(this.screenType === ScreenType.Desktop)
            {
                this.desktopView();
            }
            else
            {
                this.mobileView();
            }            
        } 
        // $(".location-info-section").removeClass("hidden");
        $(".location-listing-row").removeClass("d-none");
        stopLoader();       
    }
    loadData = async () =>
    {
        this.webLocations = await getFranchiseWebLocations();        
    }
    loadMapAndResultsList = () =>
    {
        this.loadMapAndCluster();
        const mapUi = {map: this.map, ui: this.ui};
        // instantiate ResultsList
        this.resultsList = new ResultsList(mapUi, this.webLocations);

        // instantiate Search functionality
        this.search = new Search(mapUi.map, this.resultsList);
        // if(this.webLocations)
        // {
        //     this.fullAttributeContainer = new FullAttributeContainer(this.webLocations, this.resultsList, this.search);
        // } 
        if(localStorage.getItem("isCityEntered") === "true" || localStorage.getItem("isFSAEntered") === "true")  {
        const getCityName = localStorage.getItem("locateCityname")
        this.search.search(getCityName);  
        }     
    }
    loadMapAndCluster = () => 
    {
        this.screenType = reportScreenSize();
        const mapUi = renderMap(this.screenType);
        this.map = mapUi.map;
        this.ui = mapUi.ui;

        this.cluster = new Cluster(mapUi.map, mapUi.ui);
        this.cluster.startClustering(this.webLocations);
    }
    windowResizeCallback = () =>
    {
        //console.log('windowResizeCallback called');
        var newScreenType = reportScreenSize();
        if(this.screenType === newScreenType) 
        {
            this.map.getViewPort().resize();
        }
        else if(this.screenType === ScreenType.Mobile && newScreenType === ScreenType.Desktop)
        {     
            this.desktopView();            
        }   
        else if(this.screenType === ScreenType.Desktop && newScreenType === ScreenType.Mobile)
        {
            this.mobileView();
        }
    }
    desktopView = () => 
    {
        // remove mobile-map
        document.getElementById('mobile-map-container').replaceChildren();
        document.getElementById('mobile-map-container').classList.add('d-none');
        document.getElementById('mobile-map-container-col').classList.remove('col');
        document.getElementById('mobile-map-container-col').classList.add('col-3');
        document.getElementById('desktop-map-container-col').classList.remove('d-none');
        this.loadMapAndResultsList();
    }
    mobileView = () => 
    {                
        document.getElementById('desktop-map-container-col').classList.add('d-none');
        document.getElementById('map-container').replaceChildren();
        document.getElementById('mobile-map-container-col').classList.remove('col-3');
        document.getElementById('mobile-map-container-col').classList.add('col');
        document.getElementById('mobile-map-container').classList.remove('d-none');
        document.getElementById('mobile-map-container').style.height = '30rem';
        
        this.map = null;        
        this.loadMapAndResultsList();
    }
    
}