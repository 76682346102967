import { FranchiseDetail } from "./models/FranchiseDetail";
//import data from './franchiseWebLocations.json';
import { locationSearchMapConfig, debugConfig } from './config';

export async function getFranchiseWebLocations(): Promise<void | FranchiseDetail[]>
{        
    if(debugConfig.level === 'debug') 
    {
        return getDummyData();
    }
    else 
    {
        return getDataFromApi();
    }
}

const getDataFromApi =  () => {
    return fetch(locationSearchMapConfig.franchiseWebLocationsApiCall)
        .then(response => {
            //console.log('response: ', response);
            return response.json();
        })
        .then(response =>
        {
            //console.log('getFranchiseWebLocations: ', response);
            return filterData(response);
        })
        .catch(error =>
        {
            //console.log("Error calling getFranchiseWebLocations:", error);
        });
}

const getDummyData = (): FranchiseDetail[]  => {
   return filterData(data);
};
const filterData = (data: any): FranchiseDetail[] => {
    let details = data.flatMap(f=>f.franchiseDetails);
    details = details.filter(c=>c.country ==='CAN' || c.country ==='Canada');
    let result = [];  
    // remove duplicates
    const set = new Set();
    for(let detail of details) {
        if(!set.has(detail.franchiseWebLocationId)) {
            result.push(detail);
            set.add(detail.franchiseWebLocationId);
        }
    }
    return result;
}
