import { formatPhone } from "./formatPhone";
import { FranchiseDetail } from "./models/FranchiseDetail";
import { LocationSearchMapCpkConfig } from "./models/LocationSearchMapCpkConfig";

export const printLocationBars = (franchiseDetails: FranchiseDetail[], cpkConfig: LocationSearchMapCpkConfig, pinnedWeblocationId: number = -1): string => {
    let locationBars: string[] = [];
    for (let i = 0; i < franchiseDetails.length; i++) {
        const webLocation = franchiseDetails[i];
        let locationBar = '';
        // if(pinnedWeblocationId === webLocation.franchiseWebLocationId) 
        // {
        //     locationBar = printPinnedLocationBar(webLocation, cpkConfig);
        //     locationBars.unshift(locationBar); // add it to beginning 
        // }
        // else 
        // {
            locationBar = printLocationBar(webLocation, cpkConfig,pinnedWeblocationId == webLocation.franchiseWebLocationId);
            locationBars.push(locationBar);
       // }
    }
    return locationBars.join('\n');
}
export const printLocationBar = (franchiseDetails: FranchiseDetail, cpkConfig: LocationSearchMapCpkConfig,isSelected:Boolean = false): string => {
    //let address1_2 = '';
    
    
    const addrLine1 = (franchiseDetails.showAddress && franchiseDetails.address1 && franchiseDetails.address1.length > 0) ? `<span>${franchiseDetails.address1}</span><br>` : '';
    const addrLine2 = (franchiseDetails.showAddress && franchiseDetails.address2 && franchiseDetails.address2.length > 0) ? `<span>${franchiseDetails.address2}</span><br>` : '';
    
   /*
    const addrLine1 = (franchiseDetails.address1 && franchiseDetails.address1.length > 0) ? `<span>${franchiseDetails.address1}</span><br>` : '';
    const addrLine2 = (franchiseDetails.address2 && franchiseDetails.address2.length > 0) ? `<span>${franchiseDetails.address2}</span><br>` : '';
    */
   /*
    if(franchiseDetails.showAddress === undefined)
    {
        address1_2 = wrapInPlaceholder(addrLine1 + ' ' + addrLine2);
    }
    else 
    {
        if(franchiseDetails.showAddress)
        {
            address1_2 = addrLine1 + ' ' + addrLine2;
        }        
    }
*/
    const addrLine3 = `<span>${(franchiseDetails.city && franchiseDetails.city.length > 0) ? franchiseDetails.city + ', ' : ''}
        ${(franchiseDetails.state && franchiseDetails.state.length > 0) ? franchiseDetails.state + ', ' : ''}
        ${(franchiseDetails.country && franchiseDetails.country.length > 0) ? franchiseDetails.country + ', ' : ''}
        ${(franchiseDetails.zipCode && franchiseDetails.zipCode.length > 0) ? franchiseDetails.zipCode : ''}
    </span>`;
    var websiteTrackingNumber:any = franchiseDetails.websiteTrackingPhone[0] ? franchiseDetails.websiteTrackingPhone[0] : franchiseDetails.phoneNumber;
    /*
            <span>${franchiseDetails.address1}</span><br>
            <span>${franchiseDetails.address2}</span><br>
            <span>${franchiseDetails.city}, ${franchiseDetails.state}, ${franchiseDetails.country}, ${franchiseDetails.zipCode}</span>
    */
    return `
    <div class="location-bar ${isSelected?" map-location-selected-item":""}">
        <div class="focus" tabindex="7">
            <h3 class="location-bar-title">${franchiseDetails.doingBusinessAs}</h3>            
            ${addrLine1}
            ${addrLine2}
            ${addrLine3}            
            <div class="call-bttn">
                ${cpkConfig.showPhone? printPhoneButtonLink(websiteTrackingNumber, cpkConfig.phoneViewType, cpkConfig.phoneText):""}                
            </div>
            <div class="bttns-group">
                ${cpkConfig.showViewWebsite? printButtonLink(franchiseDetails.websiteUrl, cpkConfig.viewWebsiteViewType, cpkConfig.viewWebsiteText): ""}
                ${cpkConfig.showRequestEstimate? printButtonLink("#", cpkConfig.requestEstimateViewType, cpkConfig.requestEstimateText): ""}                
            </div>
        </div>
        <input type="hidden" class="weblocation-id" value="${franchiseDetails.franchiseWebLocationId}">
    </div>`;
}

const printButtonLink = (url: string, viewType: string, label: string): string => {
    if(viewType === "cta" && label === "View Website") {
        return `<a href="${url ? url : "#"}" class="primary-btn">${label}</a>`
    }
    if(viewType === "cta" && label === "Request Estimate") {
        return `<a href="${url ? url : "#"}" class="primary-btn" data-toggle="modal" data-target="#js-schedulerDialog">${$('.desktop-btn a').html()}</a>`
    }
    return `<div class="view-website"><a href="${url}">${label}</a></div>`;
}

const printPhoneButtonLink = (phone: string, viewType: string, label: string): string => {
    if(viewType === "cta") 
    {
        return `<a href="tel:${phone}" class="${viewType === "cta" ? "primary-btn" : ""}">${label}: ${phone.includes('(') ? phone:formatPhone(phone)}</a>`
        //return `<a href="tel:${phone}" class="primary-btn">${label}</a>`;
    }
    return `<div class="view-website"><a href="tel:${phone}">${label}</a></div>`;
}

export const printPinnedLocationBar = (franchiseDetails: FranchiseDetail, cpkConfig: LocationSearchMapCpkConfig): string => {    
    const addrLine1 = (franchiseDetails.showAddress && franchiseDetails.address1 && franchiseDetails.address1.length > 0) ? `<span>${franchiseDetails.address1}</span><br>` : '';
    const addrLine2 = (franchiseDetails.showAddress && franchiseDetails.address2 && franchiseDetails.address2.length > 0) ? `<span>${franchiseDetails.address2}</span><br>` : '';
    const addrLine3 = `<span>${(franchiseDetails.city && franchiseDetails.city.length > 0) ? franchiseDetails.city + ', ' : ''}
        ${(franchiseDetails.state && franchiseDetails.state.length > 0) ? franchiseDetails.state + ', ' : ''}
        ${(franchiseDetails.country && franchiseDetails.country.length > 0) ? franchiseDetails.country + ', ' : ''}
        ${(franchiseDetails.zipCode && franchiseDetails.zipCode.length > 0) ? franchiseDetails.zipCode : ''}
    </span>`;
    /*
            <span>${franchiseDetails.address1}</span><br>
            <span>${franchiseDetails.address2}</span><br>
            <span>${franchiseDetails.city}, ${franchiseDetails.state}, ${franchiseDetails.country}, ${franchiseDetails.zipCode}</span>
    */
    return `
    <div class="location-bar location-bar-pinned">
        <div class="focus" tabindex="7">
            <h3>${franchiseDetails.doingBusinessAs}</h3>
            ${pinnedResultParaEl}
            ${addrLine1}
            ${addrLine2}
            ${addrLine3}                    
            <div class="call-bttn">
                ${cpkConfig.showPhone? printPhoneButtonLink(franchiseDetails.phoneNumber, cpkConfig.phoneViewType, cpkConfig.phoneText):""}                
            </div>
            <div class="bttns-group">
                ${cpkConfig.showViewWebsite? printButtonLink(franchiseDetails.websiteUrl, cpkConfig.viewWebsiteViewType, cpkConfig.viewWebsiteText): ""}
                ${cpkConfig.showRequestEstimate? printButtonLink("#", cpkConfig.requestEstimateViewType, cpkConfig.requestEstimateText): ""}                
            </div>
        </div>
    </div>`;
}

const pinnedResultId = 'location-search-pinned-result';

const pinnedResultParaEl: string = `<p id="${pinnedResultId}">
<svg xmlns="http://www.w3.org/2000/svg" id="a" height="57" width="57"><defs><style>.b{fill:#fff;}.c{fill:#b62126;}</style></defs><circle class="c" cx="28.19" cy="26.71" r="23.62"/><circle class="b" cx="28.19" cy="26.71" r="17.8"/><path class="c" d="M46.82,30.16c-.52-.45-1.87-.92-2.41-.62l-.07,.04-.03,.07c-.76,2.11-1.72,3.08-2.29,3.08-.28,0-.53-.14-.71-.41-.33-.48-.92-2.12,.88-6.84,.13-.34,.13-.62,0-.83-.2-.3-.62-.36-.96-.38h-.05l-.05,.02c-.62,.31-1.98,.28-3.42,.25-.19,0-.38,0-.57-.01-.05-.56-.15-1.07-.25-1.61-.05-.27-.11-.55-.15-.84-.05-.33-.3-.59-.64-.66-.74-.16-1.42-.02-1.95,.41-.74,.6-1.07,1.63-1.07,2.33,0,1.35,.55,2.16,1.73,2.51-.17,1.51-.65,3.22-1.29,4.62h-.01l-.04,.08c-.92,1.68-1.32,1.68-1.93,1.68-.23,0-.41-.1-.56-.31-1.05-1.41-.24-7.28,1.16-11.88,.19-.61-.04-1.29-.55-1.68-.92-.7-2.64-1.42-3.06-.96-.62,.69-3.32,5.4-5.16,9.49l2.26-10.33c.13-.61-.13-1.23-.66-1.57-.88-.56-2.2-1.16-3.07-1.4-.56-.15-1.14,.1-1.41,.61-1.23,2.31-2.32,5.3-3.47,8.48-.41,1.13-.83,2.29-1.26,3.42,.32-1.53,.67-2.85,1.02-4.14,.35-1.3,.67-2.53,.94-3.87,.1-.5-.02-.97-.33-1.35-.4-.48-1.11-.77-1.82-.76l-6.89,3.5-.05,.04c-.16,.16-.24,.42-.24,.79,0,.98,.61,2.67,1.15,3.18,.26,.25,.68,.16,.92-.07,1.58-1.51,2.86-2.65,3.41-3.05l-2.38,12.43c-.02,.2,.06,.37,.23,.49,.63,.46,2.6,.12,3.62-.3l.08-.03,.38-.94c1.6-3.92,3.39-8.33,5.51-12.35-.34,2.85-1.1,7.15-2.25,12.83v.05c-.08,.33,.04,.54,.16,.66,.4,.41,1.42,.45,3.3,.12l.1-.02,.05-.09c.49-.94,1-1.93,1.51-2.94,1.22-2.41,2.48-4.88,3.74-6.98-1.05,5.14-.99,7.99,.17,9.45,.61,.77,1.52,1.14,2.78,1.14,2.55,0,3.83-1.57,4.57-2.89,.87-1.44,1.47-3.17,1.65-4.84,.36,0,.75-.02,1.15-.04-.89,4.05-.15,6.08,.63,7.06,.57,.71,1.37,1.12,2.2,1.12,2.83,0,5.37-2.55,6.05-4.32,.05-.14,.05-.36-.26-.63"/></svg>
<span> Your local Mr. Handyman Location</span>
</p>`;
/*
const wrapInPlaceholder = (content: string): string =>
{
    return `<div class="placeholder-glow">
        <div class="placeholder">
            ${content}
        </div>
    </div>`;
}
*/