import Fuse from "fuse.js";
import { getBubbleContent } from "./getBubbleContent";
import { getLocationSearchMapCpkConfig } from "./getLocationSearchMapCpkConfig";
import { locationBarClick } from "./locationBarClick";
import { FranchiseDetail } from "./models/FranchiseDetail";
import { LatLng } from "./models/LatLng";
import { LocationSearchMapCpkConfig } from "./models/LocationSearchMapCpkConfig";
import { MapUI } from "./models/MapUI";
import { printLocationBar, printLocationBars } from "./printLocationBars";
import { replaceInfoBubbleCloseIcon } from "./replaceInfoBubbleCloseIcon";

export class ResultsList
{
    
    franchiseDetails: FranchiseDetail[];
    currFranchiseDetails: FranchiseDetail[];
    cpkConfig: LocationSearchMapCpkConfig;
    fuseOptions: any;
    fuseObj: any;
    pinnedWeblocationId: number = -1;
    mapUi: MapUI;
    activeInfoBubble: H.ui.InfoBubble;
    selectedWebLocationId :any =null;
    constructor(mapUi: MapUI, franchiseDetails: FranchiseDetail[])
    {
        this.franchiseDetails = franchiseDetails;
        this.currFranchiseDetails = franchiseDetails;
        const cpkConfig = getLocationSearchMapCpkConfig();
        this.cpkConfig = cpkConfig;
        this.updateListView();
        this.fuseOptions = {
            includeScore: true,
            // Search in `doingBusinessAs` in array
            keys: ['doingBusinessAs'],
            threshold: 0.2
        };
        this.fuseObj = new Fuse(this.franchiseDetails, this.fuseOptions);
        this.mapUi = mapUi;
        
    }

    filterResults = (bbox: H.geo.Rect) =>
    {
        this.currFranchiseDetails = [];
        for (let i = 0; i < this.franchiseDetails.length; i++)
        {
            if (bbox.containsLatLng(this.franchiseDetails[i].latitude, this.franchiseDetails[i].longitude) ||
            this.pinnedWeblocationId === this.franchiseDetails[i].franchiseWebLocationId
            )
            {
                this.currFranchiseDetails.push(this.franchiseDetails[i]);
            }            
        }
        //console.log('filtered result: ', this.currFranchiseDetails);
        this.updateListView();
    }

    updateListView = () =>
    {
        $('.location-bar-col')[0].remove();
        const locationsFoundText = document.querySelector('.location-listing-row');
        locationsFoundText.insertAdjacentHTML('beforeend', '<div class="location-bar-col"></div>');
        const locationBars = printLocationBars(this.currFranchiseDetails, this.cpkConfig, this.selectedWebLocationId,);
        $('.location-bar-col').append(locationBars);
        if (this.currFranchiseDetails.length > 0)
        {
            document.querySelector('.locations-found-text').innerHTML = `${this.currFranchiseDetails.length} locations found`;
            $(".location-bar-col").removeClass("d-none");
        }
        else
        {
            document.querySelector('.locations-found-text').innerHTML = this.cpkConfig.noLocationsAvailable;
            $(".location-bar-col").addClass("d-none");
        }
        this.addClickEventListenerToTitles();
    }

    resetList = () =>
    {
        this.currFranchiseDetails = this.franchiseDetails;
        this.updateListView();
    }

    searchByDBAName = (searchQuery: string) =>
    {        
        const result = this.fuseObj.search(searchQuery);
        return result;
    }

    sortCurrentListByDistanceToPin = (pin: LatLng) => {
        //console.log('before sorting: ', this.debugDisplayDistanceFromPin(pin));
        const pointPin = new H.geo.Point(pin.lat, pin.lng);
        this.currFranchiseDetails.sort((a: FranchiseDetail, b: FranchiseDetail) => {
            const pointA = new H.geo.Point(a.latitude, a.longitude);
            const pointB = new H.geo.Point(b.latitude, b.longitude);
            const dist1 = pointPin.distance(pointA);
            const dist2 = pointPin.distance(pointB);
            return dist1-dist2;
        });
        //console.log('after sorting: ', this.debugDisplayDistanceFromPin(pin));
    }
    
    debugDisplayDistanceFromPin = (pin: LatLng) => {
        const pointPin = new H.geo.Point(pin.lat, pin.lng);
        const arr = [];
        for(let i=0; i<this.currFranchiseDetails.length; ++i)
        {
            const point = new H.geo.Point(this.currFranchiseDetails[i].latitude, this.currFranchiseDetails[i].longitude);
            const dist = pointPin.distance(point);
            arr.push({
                name: this.currFranchiseDetails[i].doingBusinessAs,
                dist: dist
            });
        }
        return arr;
    }

    pinFirstResult = () => {
        this.pinnedWeblocationId = this.currFranchiseDetails[0].franchiseWebLocationId;
    }

    unpinFirstResult = () => {
        this.pinnedWeblocationId = -1;
    }

    addClickEventListenerToTitles = () => {
        // event when title on the list is clicked
        const locationBarTitles = document.getElementsByClassName('location-bar-title');
                
        for (let i = 0; i < locationBarTitles.length; i++) {
            const el = locationBarTitles[i];
            if(el) {
                el.addEventListener('click', event => {
                    const weblocationId: number = +(el.parentElement.nextSibling.nextSibling as HTMLInputElement).value;
                    const franchiseDetail = this.franchiseDetails.find(f=>f.franchiseWebLocationId === weblocationId);
                    if(franchiseDetail) {
                        this.locationBarClick(franchiseDetail);
                    }  
                    let regexp:any = /android|iphone|kindle|ipad/i;
                    let Devicedetails:any = navigator.userAgent;
                    let isMobileDevice = regexp.test(Devicedetails);
                    if(isMobileDevice){
                    $('html, body').animate({
                        scrollTop: $("#mobile-map-container-col").offset().top
                    }, 500);   
                  }               
                });
            }        
        }
    }

    locationBarClick = (franchiseDetail: FranchiseDetail) => {
        //console.log('location bar clicked');    
        
        if(this.activeInfoBubble) {
            this.mapUi.ui.removeBubble(this.activeInfoBubble);
        }    
        
        this.activeInfoBubble = new H.ui.InfoBubble({lat: franchiseDetail.latitude, lng: franchiseDetail.longitude}, {
            content: getBubbleContent(franchiseDetail)
        });
        // show info bubble
        this.mapUi.ui.addBubble(this.activeInfoBubble);
        this.mapUi.map.setCenter({lat:franchiseDetail.latitude, lng: franchiseDetail.longitude});
        this.activeInfoBubble.open();   
        replaceInfoBubbleCloseIcon();    
        
        //update selected items style
        $(".location-listing-row .location-bar-col").removeClass(".map-location-selected-item");
        this.selectedWebLocationId = franchiseDetail.franchiseWebLocationId;
        const locationBar = this.findLocationBar(franchiseDetail.franchiseWebLocationId);    
        if(locationBar){
            locationBar.classList.add("map-location-selected-item");
        }
    }

    updateLocationBar = (franchiseDetail: FranchiseDetail) =>
    {
        //console.log('updateWebLocation: weblocationId: ', franchiseDetail.franchiseWebLocationId);
        //console.log('resultsList: currentListItem: ', this.currFranchiseDetails.filter(x => x.franchiseWebLocationId === franchiseDetail.franchiseWebLocationId));
        //console.log('resultsList: fullListItem: ', this.franchiseDetails.filter(x => x.franchiseWebLocationId === franchiseDetail.franchiseWebLocationId));
        const updatedResultItemView = printLocationBar(franchiseDetail, this.cpkConfig);
        const locationBar = this.findLocationBar(franchiseDetail.franchiseWebLocationId);
        this.selectedWebLocationId = franchiseDetail.franchiseWebLocationId;
        if(locationBar)
        {
            locationBar.innerHTML = updatedResultItemView;
            locationBar.addEventListener('click', event => {
                this.locationBarClick(franchiseDetail);
            })
        }
        
    }
    
    findLocationBar = (webLocationId: number): Element | null => 
    {
        let children = document.querySelectorAll('.location-bar-col .weblocation-id');
        const index = this.currFranchiseDetails.findIndex(x => x.franchiseWebLocationId === webLocationId);
        if(index > -1)
        {
            return children[index].parentNode as Element;
        }
        return null;
    }
}