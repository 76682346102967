import { FranchiseDetail } from "./models/FranchiseDetail";
import { debugConfig } from "./config";
import { getBubbleContent } from "./getBubbleContent";
export class Cluster {
    map: H.Map; 
    ui: H.ui.UI;

    constructor(map: H.Map, ui: H.ui.UI) {
        this.map = map;
        this.ui = ui;    
    }

    startClustering = (data: FranchiseDetail[]): void => {
        //const data: FranchiseDetail[] = JSON.parse(localStorage.getItem('filteredFranchiseDetails'));
        
        var dataPoints = data.map(function (item)
        {
            return new H.clustering.DataPoint(item.latitude, item.longitude, null, item);
        });

        // Create a clustering provider with custom options for clusterizing the input
        var clusteredDataProvider = new H.clustering.Provider(dataPoints, {
            clusteringOptions: {
                // Maximum radius of the neighbourhood - should be power of 2
                eps: 32,
                // minimum weight of points required to form a cluster
                minWeight: 2,
                strategy: H.clustering.Provider.Strategy.GRID                
            },
            theme: CUSTOM_THEME
        });

        // Note that we attach the event listener to the cluster provider, and not to
        // the individual markers
        clusteredDataProvider.addEventListener('tap', this.onMarkerClick);

        // Create a layer tha will consume objects from our clustering provider
        var clusteringLayer = new H.map.layer.ObjectLayer(clusteredDataProvider);

        // To make objects from clustering provder visible,
        // we need to add our layer to the map
        this.map.addLayer(clusteringLayer);
    }
    onMarkerClick = (e) =>
    {
        var position = e.target.getGeometry(),
            // Get the data associated with that marker
            data = e.target.getData();
            // Merge default template with the data and get HTML
            if(data) {
                let bubbleContent = getBubbleContent(data),
                bubble = this.onMarkerClick.bubble;
    
                // For all markers create only one bubble, if not created yet
                if (!bubble)
                {
                    bubble = new H.ui.InfoBubble(position, {
                        content: bubbleContent
                    });
                    this.ui.addBubble(bubble);
                    // Cache the bubble object
                    this.onMarkerClick.bubble = bubble;
                } else
                {
                    // Reuse existing bubble object
                    bubble.setPosition(position);
                    bubble.setContent(bubbleContent);
                    bubble.open();
                }
        
                // Move map's center to a clicked marker
                this.map.setCenter(position, true);
        
                // replace close-icon
                if(debugConfig.level === 'debug') {
                    //console.log('marker clicked: replacing close icon');
                }    
                
                const closeElement = document.getElementsByClassName("H_ib_close H_btn")[0];
                if(closeElement) {
                    closeElement.innerHTML = bubbleCloseIcon;
                }
            }
            else {
                // cluster point is clicked
                //console.log('cluster point clicked position: ', position);
                this.map.setCenter({lat:position.lat, lng: position.lng});
                this.map.setZoom(this.map.getZoom() + 1);
            }
        
        //replaceInfoBubbleCloseIcon();
    }
}
let CUSTOM_THEME = {
    getClusterPresentation: (cluster: H.clustering.ICluster): H.map.Object =>
    {
        const svgMarkup = getCombinedLocationsMarkup(cluster.getWeight());

        const icon = new H.map.Icon(svgMarkup);
        // Get random DataPoint from our cluster
        //var randomDataPoint = getRandomDataPoint(cluster),
        // Get a reference to data object that DataPoint holds
        //data = randomDataPoint.getData();

        // Create a marker from a random point in the cluster
        var clusterMarker = new H.map.Marker(cluster.getPosition(), {
            icon: icon,

            // Set min/max zoom with values from the cluster,
            // otherwise clusters will be shown at all zoom levels:
            min: cluster.getMinZoom(),
            max: cluster.getMaxZoom()
        });

        // Link data from the random point from the cluster to the marker,
        // to make it accessible inside onMarkerClick
        //clusterMarker.setData(data);
        //clusterMarker.addEventListener('tap', onClusterMarkerClick);
        return clusterMarker;
    },
    getNoisePresentation: (noisePoint: H.clustering.INoisePoint): H.map.Object =>
    {

        const icon = new H.map.Icon(singleLocationSvgMarkup, {size: {w: 30, h: 30}});
        // Get a reference to data object our noise points
        var data = noisePoint.getData(),
            // Create a marker for the noisePoint
            noiseMarker = new H.map.Marker(noisePoint.getPosition(), {
                // Use min zoom from a noise point
                // to show it correctly at certain zoom levels:
                min: noisePoint.getMinZoom(),
                icon: icon
            });

        // Link a data from the point to the marker
        // to make it accessible inside onMarkerClick
        noiseMarker.setData(data);
        
        return noiseMarker;
    }
};
const getCombinedLocationsMarkup = (num: number) =>
{
    return `<svg xmlns="http://www.w3.org/2000/svg" height="32" width="32"><circle class="c" cx="16" cy="16" r="14" fill="none" stroke="#da291c" stroke-width="3"/><circle class="d" cx="16" cy="16" r="5" fill="#da291c" stroke="#da291c" stroke-width="10"/><text stroke="#fff" alignment-baseline="middle" text-anchor="middle" x="16" y="16" fill="#fff" font-size=".8em">${num}</text></svg>`
}
//const singleLocationSvgMarkup = '<svg xmlns="http://www.w3.org/2000/svg" id="a" height="100" width="100"><defs><style>.b{fill:#fff;}.c{fill:#b62126;}</style></defs><circle class="c" cx="28.19" cy="26.71" r="23.62"/><circle class="b" cx="28.19" cy="26.71" r="17.8"/><path class="c" d="M46.82,30.16c-.52-.45-1.87-.92-2.41-.62l-.07,.04-.03,.07c-.76,2.11-1.72,3.08-2.29,3.08-.28,0-.53-.14-.71-.41-.33-.48-.92-2.12,.88-6.84,.13-.34,.13-.62,0-.83-.2-.3-.62-.36-.96-.38h-.05l-.05,.02c-.62,.31-1.98,.28-3.42,.25-.19,0-.38,0-.57-.01-.05-.56-.15-1.07-.25-1.61-.05-.27-.11-.55-.15-.84-.05-.33-.3-.59-.64-.66-.74-.16-1.42-.02-1.95,.41-.74,.6-1.07,1.63-1.07,2.33,0,1.35,.55,2.16,1.73,2.51-.17,1.51-.65,3.22-1.29,4.62h-.01l-.04,.08c-.92,1.68-1.32,1.68-1.93,1.68-.23,0-.41-.1-.56-.31-1.05-1.41-.24-7.28,1.16-11.88,.19-.61-.04-1.29-.55-1.68-.92-.7-2.64-1.42-3.06-.96-.62,.69-3.32,5.4-5.16,9.49l2.26-10.33c.13-.61-.13-1.23-.66-1.57-.88-.56-2.2-1.16-3.07-1.4-.56-.15-1.14,.1-1.41,.61-1.23,2.31-2.32,5.3-3.47,8.48-.41,1.13-.83,2.29-1.26,3.42,.32-1.53,.67-2.85,1.02-4.14,.35-1.3,.67-2.53,.94-3.87,.1-.5-.02-.97-.33-1.35-.4-.48-1.11-.77-1.82-.76l-6.89,3.5-.05,.04c-.16,.16-.24,.42-.24,.79,0,.98,.61,2.67,1.15,3.18,.26,.25,.68,.16,.92-.07,1.58-1.51,2.86-2.65,3.41-3.05l-2.38,12.43c-.02,.2,.06,.37,.23,.49,.63,.46,2.6,.12,3.62-.3l.08-.03,.38-.94c1.6-3.92,3.39-8.33,5.51-12.35-.34,2.85-1.1,7.15-2.25,12.83v.05c-.08,.33,.04,.54,.16,.66,.4,.41,1.42,.45,3.3,.12l.1-.02,.05-.09c.49-.94,1-1.93,1.51-2.94,1.22-2.41,2.48-4.88,3.74-6.98-1.05,5.14-.99,7.99,.17,9.45,.61,.77,1.52,1.14,2.78,1.14,2.55,0,3.83-1.57,4.57-2.89,.87-1.44,1.47-3.17,1.65-4.84,.36,0,.75-.02,1.15-.04-.89,4.05-.15,6.08,.63,7.06,.57,.71,1.37,1.12,2.2,1.12,2.83,0,5.37-2.55,6.05-4.32,.05-.14,.05-.36-.26-.63"/></svg>';
// <a class="close-icon">X</a>
const singleLocationSvgMarkup = "/ca/en-ca/mr-rooter/_assets/images/Geo/mrr-map-icon.svg";
const bubbleCloseIcon = '<svg class="H_icon" viewBox="0 0 12 12"><path d="m8.12 6 3.66-3.66c.29-.29.29-.76 0-1.05L10.72.23a.742.742 0 0 0-1.05 0L6.01 3.89 2.35.23a.742.742 0 0 0-1.05 0L.24 1.29c-.29.29-.3.76 0 1.05L3.9 6 .24 9.66c-.29.29-.29.76 0 1.05l1.06 1.06c.29.29.76.29 1.05 0l3.66-3.66 3.66 3.66c.29.29.76.29 1.05 0l1.06-1.06c.29-.29.3-.76 0-1.05z" fill="#fff"/></svg>';
