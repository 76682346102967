import { locationSearchMapConfig as config} from './config';
import { moveMapTo } from "./moveMapTo";
import { MapUI } from './models/MapUI';
import { ScreenType } from './models/ScreenType';
export const renderMap = (screenType: ScreenType): MapUI =>
{
    /**
   * Boilerplate map initialization code starts below:
   */

    //Step 1: initialize communication with the platform
    // In your own code, replace variable window.apikey with your own apikey
    var platform = new H.service.Platform({
        apikey: 'JM8QbmZwlyQePH_AKtYlsKu69whGkWYGocqgWEk4Jqc'
    });
    var defaultLayers = platform.createDefaultLayers();

    //Step 2: initialize a map - this map is centered over Europe    
    var map = getMapDefault(platform, screenType);

    // add a resize listener to make sure that the map occupies the whole container
    
    // following line is moved to App.ts:  
    // window.addEventListener('resize', () => map.getViewPort().resize());

    //Step 3: make the map interactive
    // MapEvents enables the event system
    // Behavior implements default interactions for pan/zoom (also on mobile touch environments)
    var behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

    // Create the default UI components
    var ui = H.ui.UI.createDefault(map, defaultLayers);
    const zoom = ui.getControl('zoom');
    zoom.setAlignment('left-top');
    if(screenType === ScreenType.Desktop) {
        moveMapTo(map, 
            {lat: Number(config.initView.lat), lng: Number(config.initView.lng)}, 
            Number(config.initView.zoom));
    }else {
        moveMapTo(map, 
            {lat: Number(config.initView.lat), lng: Number(config.initView.lng)}, 
            Number(config.initView.mobileZoom));
    }
    

    const baseLayerProvider = map.getBaseLayer().getProvider();
    const style = baseLayerProvider.getStyle();
    //console.log('renderMap base layer style: ', style);

    return {map: map, ui: ui};
    
}

// black-and-white rendering of map
const getMapReducedDay = (platform: H.service.Platform): H.Map => {    
    var reduced = platform.getMapTileService({
        type: 'base'
      }).createTileLayer("maptile", "reduced.day", 512, "png", {opt_dark: true});
    var map = new H.Map(document.getElementById('map-container'),
        reduced, {
        center: {lat: Number(config.initView.lat), lng: Number(config.initView.lng)},
        zoom: 1,
        pixelRatio: window.devicePixelRatio || 1
    });
    return map;
}

const getMapDefault = (platform: H.service.Platform, screenType: ScreenType): H.Map => {
    var defaultLayers = platform.createDefaultLayers();
    const containerId = screenType === ScreenType.Desktop ? 'map-container' : 'mobile-map-container';
    var map = new H.Map(document.getElementById(containerId),
        defaultLayers.vector.normal.map, {
        center: {lat: Number(config.initView.lat), lng: Number(config.initView.lng)},
        zoom: 1,
        pixelRatio: window.devicePixelRatio || 1
    });
    return map;
}