export const getConceptCode = (): string => 
{
    let domainName:any = location.hostname.toLowerCase();
    let concept_Code:any;
    if(domainName.indexOf("-ca-") > -1 || domainName.indexOf(".ca") > -1) {
        let Ca_ConceptCode = $('#conceptCode').val();
        if(Ca_ConceptCode === 'MHM') {
            concept_Code = "HMC";
        }else {
            concept_Code = $('#conceptCode').val();
        }
    }else { 
        concept_Code = $('#conceptCode').val();
    }
    return concept_Code;
}